<template>
  <base-widget class="toc">
    <span slot="header">
      Supplementary Material
    </span>
    <div slot="body">
      <ul class="toc-list">
        <li v-for="entry in toc" :key="entry.page_name"><a :href="'/' + reference + '/' + entry.page_name">{{entry.label}}</a></li>
      </ul>
    </div>
  </base-widget>
</template>

<style scoped>
  .toc-list {
    font-family: "Noto Sans", "Noto Sans Coptic";
    font-size: 0.9rem;
  }
  .toc-list a {
    font-size: 0.9rem;
   }
</style>

<script>
export default {
  name: 'widget-table-of-content',
  props: ['toc', 'reference'],
};
</script>
