<template>
  <div>
    <template v-if="!ready">
      <div class="wrapper">
        <page-loader v-show="showLoader" />
      </div>
    </template>
    <div v-else-if="error" class="wrapper" :style="{ margin: 'auto' }">
      <div class="alert alert-danger" role="alert">
        <b>Error</b>: {{ error }}
      </div>
    </div>
    <template v-else>
      <skeleton>
        <template slot="left">
          <div class="left-widget-wrapper">
            <widget-passage-citation-TOC />
            <widget-passage-ancestors />
            <widget-passage-children />
            <widget-passage-reference />
            <widget-search />
            <widget-table-of-contents v-if="toc.length && toc.length > 0" :toc="toc" :reference="reference" />
          </div>
          <div class="left-footer">
            <ul>
              <li class="left-footer-inline-item"><a href="https://brill.com/page/terms%20&%20Conditions/terms-and-conditions">Terms and Conditions</a></li>
              <li class="left-footer-inline-item"><a href="https://brill.com/page/Privacy%20Statement/privacy-statement">Privacy Statement</a></li>
              <li class="left-footer-inline-item"><a :href="accessibilityURL">Accessibility</a></li>
            </ul>
          </div>
        </template>
        <template slot="body">
          <div class="top-navbar" v-if="text.metadata.product == 'SEGO'">
              <div class="top-navbar-button">
                <a v-if="passage.metadata.prev_sego" :href="passage.metadata.prev_sego.url">
                  <span><icon name="arrow-left"></icon></span>
                  PREV
                </a>
              </div>
              <div class="top-navbar-button">
                <a v-if="passage.metadata.next_sego" :href="passage.metadata.next_sego.url">
                  NEXT
                  <span><icon name="arrow-right"></icon></span>
                </a>
              </div>
          </div>
          <TopNavBarPez />
          <div class="passage-heading" style="padding-right: 32px;">
            <h1>
              <template v-for="(breadcrumb, idx) in text.metadata.ancestors">
                <a :key="breadcrumb.urn" :href="breadcrumb.url">{{ breadcrumb.label }}</a><template v-if="idx != text.metadata.ancestors.length - 1">, </template>
              </template>
            </h1>
            <template v-if="!rightPassage">
              <h2 v-if="canSelectVersions">
                {{ leftText.metadata.label }}
              </h2>
              <h2
                v-else-if="parallelTextMetadata"
                class="parallel-text-metadata"
              >
                <span v-if="!parallelTextMetadata">
                  {{ leftText.metadata.label }}
                </span>
                <span v-else>
                  {{ leftText.metadata.label }} ({{ leftText.metadata.human_lang }} {{ leftText.metadata.kind }}) ::
                  {{ parallelTextMetadata.label }} ({{ parallelTextMetadata.human_lang }} {{ parallelTextMetadata.kind }})
                </span>
              </h2>
              <h3><passage-human-reference :metadata="leftPassage.metadata" /></h3>
            </template>
          </div>
          <version-selector v-if="canSelectVersions && !usesParallelVersions" :versions="versions" :to="toRightPassage">
            <icon name="columns"></icon>
            select a parallel version <span v-tooltip="'Here you can juxtapose a commentary, text, translation etc. for comparison.'">
              <i class="fas fa-info-circle" />
            </span>
          </version-selector>
          <version-alignment-selector v-if="usesParallelVersions">
            <icon name="columns" />
            select a parallel version <span v-tooltip="'Here you can juxtapose a commentary, text, translation etc. for comparison.'">
              <i class="fas fa-info-circle" />
            </span>
          </version-alignment-selector>
          <div id="overall" class="overall">
            <div :class="lowerPassageText ? 'upper' : 'upper-lower'">
              <div class="pg-left">
                <router-link v-if="(text.metadata.rtl && passage.metadata.next) || (!text.metadata.rtl && passage.metadata.prev)"
                             :to="text.metadata.rtl ? toRef(passage.metadata.next.ref) : toRef(passage.metadata.prev.ref)">
                  <span><icon name="chevron-left"></icon></span>
                </router-link>
                <div class="spacer" v-else />
              </div>
              <template v-if="rightUrn">
                <div class="left">
                  <version-selector v-if="!usesParallelVersions" :versions="versions" :to="toPassage" :remove="toRemoveLeft">
                    {{ leftText.metadata.label }}
                    <div class="metadata">{{ leftText.metadata.human_lang }} {{ leftText.metadata.kind }}</div>
                  </version-selector>
                  <div v-if="leftPassage.error" class="alert text-danger" role="alert">
                    Failed to load <b>{{ leftPassage.urn.toString() }}</b>: {{ leftPassage.error }}
                  </div>
                  <template v-else>
                    <passage-redirect-notice v-if="leftPassage.redirected" :passage="leftPassage" />
                    <passage-cannot-render-tokens v-if="leftPassage.metadata && !leftPassage.metadata.can_render_tokens" />
                    <passage-render-text v-if="leftPassage.metadata && leftPassage.metadata.access" :text="leftPassageText" pane="left" :highlighting="true" :commentary="true" :set-available-refs="true" />
                    <no-access v-else />
                  </template>
                </div>
                <div class="right">
                  <template v-if="rightText && rightText.metadata">
                    <version-selector v-if="!usesParallelVersions" :versions="versions" :to="toRightPassage" :remove="toRemoveRight">
                      {{ rightText.metadata.label }}
                      <div class="metadata">{{ rightText.metadata.human_lang }} {{ rightText.metadata.kind }}</div>
                    </version-selector>
                    <div v-if="rightPassage.error" class="alert text-danger" role="alert">
                      Failed to load <b>{{ rightPassage.urn.toString() }}</b>: {{ rightPassage.error }}
                    </div>
                    <template v-else>
                      <passage-redirect-notice v-if="rightPassage.redirected" :passage="rightPassage" />
                      <passage-cannot-render-tokens v-if="rightPassage.metadata && !rightPassage.metadata.can_render_tokens" />
                      <passage-render-text v-if="rightPassage.metadata && rightPassage.metadata.access" :text="rightPassageText" pane="right" :highlighting="true" :set-available-refs="false" />
                      <no-access v-else />
                    </template>
                  </template>
                </div>
              </template>
              <div v-else>
                <div v-if="leftPassage.error" class="alert text-danger" role="alert">
                  Failed to load <b>{{ leftPassage.urn.toString() }}</b>: {{ leftPassage.error }}
                </div>
                <template v-else>
                  <passage-redirect-notice v-if="leftPassage.redirected" :passage="leftPassage" />
                  <passage-cannot-render-tokens v-if="leftPassage.metadata && !leftPassage.metadata.can_render_tokens" />
                  <passage-render-text v-if="leftPassage.metadata && leftPassage.metadata.access" :text="leftPassageText" pane="left" :highlighting="true" :commentary="true" :set-available-refs="true" />
                  <no-access v-else />
                </template>
              </div>
              <div class="pg-right">
                <router-link v-if="(text.metadata.rtl && passage.metadata.prev) || (!text.metadata.rtl && passage.metadata.next)"
                             :to="text.metadata.rtl ? toRef(passage.metadata.prev.ref) : toRef(passage.metadata.next.ref)">
                  <span><icon name="chevron-right"></icon></span>
                </router-link>
              </div>
            </div>
            <div class="lower" v-if="lowerPassageText">
              <passage-render-text v-if="lowerPassage.metadata && lowerPassage.metadata.access" :text="lowerPassageText" pane="lower" :highlighting="false" :set-available-refs="false"/>
              <no-access v-else />
            </div>
          </div>
        </template>
        <template slot="right">
          <widget-text-metadata />
          <widget-metadata-records />
          <widget-metadata-records-pez />
          <widget-commentary v-if="showCommentaryWidget" />
          <widget-concordance-entries />
          <widget-morphology />
          <widget-montanari v-if="showMontanariWidget"/>
          <widget-montanari-no-access v-if="showMontanariNoAccess"/>
          <widget-dictionary-entries />
          <widget-passage-links />
          <widget-passage-exports />
          <widget-text-mode />
          <widget-text-size />
          <widget-text-width />
          <widget-highlight />
        </template>
      </skeleton>
    </template>
  </div>
</template>

<script>
import { CommentaryWidgetSV1 as WidgetCommentary } from '@scaife-viewer/widget-commentary';
import { SET_TEXT_WIDTH } from '@scaife-viewer/store';
import WIDGETS_NS from '@scaife-viewer/scaife-widgets';

import constants from '../constants';
import Skeleton from './Skeleton.vue';
import PassageHumanReference from './PassageHumanReference.vue';
import PassageRenderText from './PassageRenderText.vue';
import PassageRedirectNotice from './PassageRedirectNotice.vue';
import PassageCannotRenderTokens from './PassageCannotRenderTokens.vue';
import ReaderNavigationMixin from '../mixins/ReaderNavigationMixin.vue';
import URN from '../urn';
import VersionSelector from './VersionSelector.vue';
import VersionAlignmentSelector from './VersionAlignmentSelector.vue';
import WidgetPassageCitationTOC from './widgets/WidgetPassageCitationTOC.vue';
import WidgetPassageAncestors from './widgets/WidgetPassageAncestors.vue';
import WidgetPassageChildren from './widgets/WidgetPassageChildren.vue';
import WidgetPassageReference from './widgets/WidgetPassageReference.vue';
import WidgetSearch from './widgets/WidgetSearch.vue';
import WidgetHighlight from './widgets/WidgetHighlight.vue';
import WidgetPassageLinks from './widgets/WidgetPassageLinks.vue';
import WidgetPassageExports from './widgets/WidgetPassageExports.vue';
import WidgetConcordanceEntries from './widgets/WidgetConcordanceEntries.vue';
import WidgetTextMetadata from './widgets/WidgetTextMetadata.vue';
import WidgetTextMode from './widgets/WidgetTextMode.vue';
import WidgetTextSize from './widgets/WidgetTextSize.vue';
import WidgetTextWidth from './widgets/WidgetTextWidth.vue';
import WidgetMorphology from './widgets/WidgetMorphology.vue';
import WidgetMetadataRecords from './widgets/WidgetMetadataRecords.vue';
import WidgetMetadataRecordsPez from './widgets/WidgetMetadataRecordsPez.vue';
import WidgetWordList from './widgets/WidgetWordList.vue';
import WidgetTokenList from './widgets/WidgetTokenList.vue';
import WidgetDictionaryEntries from './widgets/WidgetDictionaryEntries.vue';
import WidgetMontanari from './widgets/WidgetMontanari.vue';
import WidgetMontanariNoAccess from '@/js/reader/widgets/WidgetMontanariNoAccess.vue';
import WidgetCHSCommentary from './widgets/WidgetCHSCommentary.vue';
import { TextPartRequest } from '../tracking';
import TopNavBarPez from '@/js/reader/TopNavBarPez.vue';
import WidgetTableOfContents from '@/js/reader/widgets/WidgetTableOfContents.vue';


const widgets = {
  WidgetPassageCitationTOC,
  WidgetPassageAncestors,
  WidgetPassageChildren,
  WidgetPassageReference,
  WidgetDictionaryEntries,
  WidgetMontanari,
  WidgetSearch,
  WidgetPassageLinks,
  WidgetCommentary,
  WidgetPassageExports,
  WidgetConcordanceEntries,
  WidgetTextMetadata,
  WidgetTextMode,
  WidgetTextSize,
  WidgetTextWidth,
  WidgetHighlight,
  WidgetMorphology,
  WidgetWordList,
  WidgetMetadataRecords,
  WidgetMetadataRecordsPez,
  WidgetTokenList,
  'widget-chs-commentary': WidgetCHSCommentary,
  WidgetTableOfContents,
};

export default {
  name: 'Reader',
  components: {
    WidgetMontanariNoAccess,
    TopNavBarPez,
    Skeleton,
    PassageHumanReference,
    PassageRenderText,
    PassageRedirectNotice,
    PassageCannotRenderTokens,
    VersionSelector,
    VersionAlignmentSelector,
    ...widgets,
  },
  mixins: [
    ReaderNavigationMixin,
  ],
  data() {
    return {
      ready: false,
      showLoader: false,
      doLiblynxCount: false
    };
  },
  computed: {
    showCommentaryWidget() {
      const flagSet = document.querySelectorAll('[data-show-commentary-widget=true]').length > 0;
      if (flagSet) {
        return true;
      }
      return this.$route.query.show_commentary_widget === 'y';
    },
    useHorizontalVersionAlignments() {
      const flagSet = document.querySelectorAll('[data-use-horizontal-version-alignments=true]').length > 0;
      if (flagSet) {
        return true;
      }
      return this.$route.query.use_horizontal_version_alignments === 'y';
    },
    showMontanariWidget() {
      const flagSet = document.querySelectorAll('[data-show-montanari-widget=true]').length > 0;
      if (flagSet) {
        return true;
      }
      return this.$route.query.use_montanari_widget === 'y';
    },
    showMontanariNoAccess() {
      const flagSet = document.querySelectorAll('[data-show-montanari-noaccess=true]').length > 0;
      if (flagSet) {
        return true;
      }
      return this.$route.query.use_montanari_noaccess === 'y';
    },
    parallelVersion() {
      return this.$route.query.parallel_version_urn;
    },
    previousParallelVersion() {
      const $rs = this.$store.state.reader;
      return (
        $rs.leftPassage
        && $rs.leftPassage.metadata
        && $rs.leftPassage.parallel_version
          ? $rs.leftPassage.parallel_version.urn
          : undefined
      );
    },
    error() {
      return this.$store.state.reader.error;
    },
    versions() {
      const { versions } = this.$store.state.reader;
      return versions && Object.keys(versions.collections).map(key => versions.collections[key]);
    },
    canSelectVersions() {
      // NOTE: We only support parallel versions _or_ aligned parallel versions at this time;
      // future iterations may add a toggle between them
      return !this.rightPassage && this.versions && this.versions.length > 1 && !this.parallelTextMetadata;
    },
    text() {
      return this.$store.getters['reader/text'];
    },
    leftText() {
      return this.$store.state.reader.leftText;
    },
    rightText() {
      return this.$store.state.reader.rightText;
    },
    lowerText() {
      return this.$store.state.reader.lowerText;
    },
    passage() {
      return this.$store.getters['reader/passage'];
    },
    parallelTextMetadata() {
      return this.passage ? this.passage.metadata.parallel_version : {};
    },
    leftPassage() {
      return this.$store.state.reader.leftPassage;
    },
    rightPassage() {
      return this.$store.state.reader.rightPassage;
    },
    lowerPassage() {
      return this.$store.state.reader.lowerPassage;
    },
    leftPassageText() {
      return this.$store.state.reader.leftPassageText;
    },
    rightPassageText() {
      return this.$store.state.reader.rightPassageText;
    },
    lowerPassageText() {
      return this.$store.state.reader.lowerPassageText;
    },
    leftUrn() {
      return new URN(this.$route.params.urn);
    },
    rightUrn() {
      const { right } = this.$route.query;
      if (!right) {
        return null;
      }
      return this.leftUrn.replace({ version: right });
    },
    parallelUrn() {
      const { parallel_version_urn } = this.$route.query;
      if (!parallel_version_urn) {
        return null;
      }
      const parallel_urn = new URN(parallel_version_urn)
      // The parallelUrn does not contain the reference. We need to add it for correct counting in Liblynx.
      parallel_urn.reference = this.leftUrn.reference
      return parallel_urn;
    },
    lowerUrn() {
      const { lower } = this.$route.query;
      if (!lower) {
        return null;
      }
      return new URN(lower);
    },
    baseUrl() {
      return this.$router.options.base;
    },
    accessibilityURL() {
      return `${this.baseUrl}accessibility/`;
    },
    usesParallelVersions() {
      // TODO: Query this from manifest / ATLAS vs hard-coded
      if (this.useHorizontalVersionAlignments) {
        return this.passage ? ['fgrh','pez'].includes(this.passage.urn.textGroup) : false;
      }
      return false;
    },
    toc() {
      const manifest = this.text.metadata.manifest
      if( manifest.hasOwnProperty('toc')) {
        return manifest.toc;
      } else {
        return [];
      }
    },
    reference() {
      const manifest = this.text.metadata.manifest
      if( manifest.hasOwnProperty('reference')) {
        return manifest.reference;
      } else {
        return "";
      }
    }
  },
  watch: {
    $route: {
      handler() {
        this.sync({ initial: false }).then(() => {
          this.handleLiblynxCount()
        });
      }
    },
    rightUrn: {
      immediate: true,
      handler(newVal, oldVal) {
        if (this.rightUrn) {
          const newTxt = newVal ? newVal.upTo('reference') : '';
          const oldTxt = oldVal ? oldVal.upTo('reference') : '';

          if( newTxt !== oldTxt ) {
            this.doLiblynxCount = true;
          }
        }
      }
    },
    leftUrn: {
      immediate: true,
      handler(newVal, oldVal) {
        if (this.leftUrn) {
          const newTxt = newVal ? newVal.upTo('reference') : '';
          const oldTxt = oldVal ? oldVal.upTo('reference') : '';

          if( newTxt !== oldTxt ) {
            this.doLiblynxCount = true;
          }
        }
      }
    },

    parallelUrn: {
      immediate: true,
      handler(newVal, oldVal) {
        if (this.parallelUrn) {
          const newTxt = newVal ? newVal.upTo('reference') : '';
          const oldTxt = oldVal ? oldVal.upTo('reference') : '';
          if( newTxt !== oldTxt ) {
            this.doLiblynxCount = true;
          }
        }
      }
    },
    parallelVersion: {
      immediate: true,
      handler(newVal, oldVal) {
        /*
        Set the reader width to full when a parallel version
        is added; revert to normal when it is removed.
        */
        const nowEnabled = newVal && !oldVal;
        const wasEnabled = oldVal && !newVal;
        if (nowEnabled || wasEnabled) {
          const widthParams = { width: newVal ? 'full' : 'normal' };
          this.$store.dispatch(`${WIDGETS_NS}/${SET_TEXT_WIDTH}`, widthParams);
        }
      },
    },
  },
  mounted() {
    setTimeout(() => { this.showLoader = true; }, 50);
    this.sync({ initial: true }).then(() => {
      this.ready = true;
      window.addEventListener('keyup', this.handleKeyUp);
      this.handleLiblynxCount()
    });
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.handleKeyUp);
  },
  methods: {
    sync({ initial = false }) {
      const { leftUrn, rightUrn, parallelUrn, lowerUrn } = this;
      const { query } = this.$route;
      return this.$store.dispatch(`reader/${constants.READER_LOAD}`, {
        leftUrn, rightUrn, parallelUrn, lowerUrn, query, initial,
      });
    },
    handleKeyUp(e) {
      if (e.key === 'ArrowLeft') {
        let ref;
        if (this.text.metadata.rtl) {
          if (this.passage.metadata.next) {
            ref = this.passage.metadata.next.ref; // eslint-disable-line prefer-destructuring
          }
        }
        if (!this.text.metadata.rtl) {
          if (this.passage.metadata.prev) {
            ref = this.passage.metadata.prev.ref; // eslint-disable-line prefer-destructuring
          }
        }
        if (ref) {
          this.$router.push(this.toRef(ref));
        }
      } else if (e.key === 'ArrowRight') {
        let ref;
        if (this.text.metadata.rtl) {
          if (this.passage.metadata.prev) {
            ref = this.passage.metadata.prev.ref; // eslint-disable-line prefer-destructuring
          }
        }
        if (!this.text.metadata.rtl) {
          if (this.passage.metadata.next) {
            ref = this.passage.metadata.next.ref; // eslint-disable-line prefer-destructuring
          }
        }
        if (ref) {
          this.$router.push(this.toRef(ref));
        }
      }
    },
    handleLiblynxCount() {

      if( ! this.doLiblynxCount ) {
        // Searching and highlighting in the Scaife viewer change the route, but should not trigger a count
        return;
      }
      this.doLiblynxCount = false;

      const leftUrnText = this.leftPassage ? this.leftPassage.urn.upTo('reference') : '';
      const rightUrnText = this.rightPassage ? this.rightPassage.urn.upTo('reference') : '';
      const parallelUrnText = this.parallelUrn ? this.parallelUrn.upTo('reference') : '';

      let parallelText = '';
      if( this.leftPassage && this.leftPassage.metadata.parallel_version && this.leftText ) {
        parallelText = {
          metadata: this.leftPassage.metadata.parallel_version,
          urn: ''
        }
        parallelText.metadata.product = this.leftText.metadata.product
        parallelText.metadata.product_reference = this.leftText.metadata.product_reference
        parallelText.metadata.manifest = this.leftText.metadata.manifest
      }

      if( leftUrnText && this.leftText) {
        //this.$counter.track('Request', TextPartRequest({ ...this.leftText, urn: leftUrnText }));
      }
      if( rightUrnText && this.rightText) {
        //this.$counter.track('Request', TextPartRequest({ ...this.rightText, urn: rightUrnText }));
      }
      if( parallelUrnText && parallelText !== '') {
        //this.$counter.track('Request', TextPartRequest({ ...parallelText, urn: parallelUrnText }));
      }
    },
  },
};
</script>
