import { render, staticRenderFns } from "./WidgetTableOfContents.vue?vue&type=template&id=261cd5a5&scoped=true&"
import script from "./WidgetTableOfContents.vue?vue&type=script&lang=js&"
export * from "./WidgetTableOfContents.vue?vue&type=script&lang=js&"
import style0 from "./WidgetTableOfContents.vue?vue&type=style&index=0&id=261cd5a5&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "261cd5a5",
  null
  
)

component.options.__file = "WidgetTableOfContents.vue"
export default component.exports