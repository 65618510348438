<template>
  <section v-if="selectedMetadata">
    <div
          v-for="refObj in selectedMetadata"
          :key="refObj.id"
          class="by-ref-group"
        >
      <div
          :class="{'selected': isSelected(refObj.ref)}"
          class="textpart-ref"
          @click="toggleSelectedRef(refObj.ref)"
        >
          {{ refObj.ref }}
      </div>
      <div
          v-if="side !== ''"
          class="textpart-ref"
          @click="toggleSelectedRef(refObj.ref)"
        >
          <p class="side">{{side}}</p>
      </div>
      <div class="group-container">
        <div
            v-for="(records, label) in refObj.recordsByLabel"
            :key="label"
            class="group"
          >
            <div class="label">
              {{ capFirst(dehydrated(label)) }}:
            </div>
            <div class="values-container">
              <div
                v-for="record in records"
                :key="record.id"
                class="value"
                v-html="formatAndDisplay(record)"
              />
            </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import constants from "@/js/constants";

export default {
  name: 'MetadataRecord',
  props: {
    selectedMetadata: {
        type: Array,
        default: () => []
    },
    side: {
      type: String,
      default: ''
    }
  },
  computed: {
    selectedRefs() {
      return this.$store.getters['reader/selectedRefs'];
    },
  },
  methods: {
    capFirst(value) {
      if( ['LGGA'].includes(value.toUpperCase(value))) {
        return value.toUpperCase(value);
      } else if( ['JACOBY ONLINE'].includes(value.toUpperCase(value))) {
        return "Jacoby Online";
      }
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    dehydrated(value) {
      // TODO: Improve verbose metadata labels
      return value.split('_').join(' ');
    },
    toggleSelectedRef(ref) {
      // TODO: Support multiple selection and refactor with TextPart.vue
      if (this.isSelected(ref)) {
        this.$store.dispatch(`reader/${constants.READER_SET_SELECTED_REF}`, { ref: null });
      } else {
        this.$store.dispatch(`reader/${constants.READER_SET_SELECTED_REF}`, { ref });
      }
    },
    isSelected(ref) {
      return this.selectedRefs.includes(ref);
    },
    formatAndDisplay(record) {
      const data = Object.assign({}, record);
      const key = data.label
      // console.log(record)
      if( data.datatype === 'obj') {
          const obj = JSON.parse(data.valueObj)

          // Clickable link
          if( Object.hasOwn(obj, 'name') && Object.hasOwn(obj, 'ref')) {
            console.log(key)
             if (obj.ref !== "") {
              data.value = `<a href="${obj.ref}" target="_blank">${obj.name}</a>`;
             } else {
              data.value = obj.name;
             }
          }
      }

      return data.value
    }
  }

}
</script>

<style lang="scss" scoped>
  // TODO: Implement proper variables
  .by-ref-group {
    // NOTE: CSS variables would be used to customize fonts under SV 2
    font-family: var(
      --sv-widget-metadata-records-font-family,
      "Noto Sans",
      "Noto Sans Coptic"
    );
    font-size: 14px; // NOTE: This was taken from the SV2 metadata widget
    margin-block-end: 10px;
    display: flex;
    padding: 4px;
  }
  .textpart-ref {
    // TODO: $gray-600;
    color: #868e96;
    padding: 4px;
    cursor: pointer;
    min-width: 3rem;
    // TODO: Mimic hover in TextPart.vue
  }
  .group-container {
    width: 100%;
  }
  .group {
    display: flex;
    padding: 2px;
  }
  .label {
    padding: 2px;
    // NOTE: sidebar max is 600px, so 30% is 180px
    // TODO: Any other calculations we can do here?
    width: clamp(80px, 30%, 180px);
    flex-shrink: 0;
    text-align: right;
    font-weight: bold;
  }
  .values-container {
    padding-inline-start: 8px;
  }
  .value {
    padding: 2px;
  }
  .selected {
    color: #495057;
    background-color: #e9ecef;
  }
</style>